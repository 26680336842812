@use "sass:math";

@import "../global/variables.scss";
@import "../global/mixins.scss";

@keyframes infinite-rotate-1 {
  from {
    transform: rotate(0);
  }
  20% {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes infinite-rotate-2 {
  from {
    transform: rotate(20deg);
  }
  20% {
    transform: rotate(380deg);
  }
  to {
    transform: rotate(380deg);
  }
}
@keyframes infinite-rotate-3 {
  from {
    transform: rotate(40deg);
  }
  20% {
    transform: rotate(400deg);
  }
  to {
    transform: rotate(400deg);
  }
}
@keyframes infinite-rotate-4 {
  from {
    transform: rotate(60deg);
  }
  20% {
    transform: rotate(420deg);
  }
  to {
    transform: rotate(420deg);
  }
}
.container {
  position: relative;

  transform: rotate(-45deg);

  width: 100%;
  height: 100%;
}
.palette {
  $animation-duration: 10s;
  $animation-delay: math.div($animation-duration, 32);
  $base-z-index: 400;

  position: absolute;
  height: 100%;
  width: 100%;
  z-index: $base-z-index + 30;
  animation-name: infinite-rotate-1;
  animation-iteration-count: infinite;
  animation-duration: $animation-duration;
  @media (prefers-reduced-motion) {
    animation: none;
  }
  .color-1 {
    fill: $color-gg-6;
  }
  .color-2 {
    fill: $color-gg-5;
  }
  .color-3 {
    fill: $color-gg-4;
  }

  &-2 {
    transform: rotate(20deg);
    animation-name: infinite-rotate-2;
    animation-delay: $animation-delay;
    z-index: $base-z-index + 20;
    .color-1 {
      fill: $color-gg-3;
    }
    .color-2 {
      fill: $color-gg-2;
    }
    .color-3 {
      fill: $color-gg-1;
    }
  }
  &-3 {
    transform: rotate(40deg);
    animation-name: infinite-rotate-3;
    animation-delay: $animation-delay * 2;
    z-index: $base-z-index + 10;
    .color-1 {
      fill: $color-mg-6;
    }
    .color-2 {
      fill: $color-mg-5;
    }
    .color-3 {
      fill: $color-mg-4;
    }
  }
  &-4 {
    transform: rotate(60deg);
    animation-name: infinite-rotate-4;
    animation-delay: $animation-delay * 3;
    z-index: $base-z-index;

    .color-1 {
      fill: $color-mg-3;
    }
    .color-2 {
      fill: $color-mg-2;
    }
    .color-3 {
      fill: $color-mg-1;
    }
  }
}

.color-border-1 {
  fill: $color-gg-1;
}
.color-border-2 {
  fill: #fff;
}

.color-bottom {
  fill: #fff;
}

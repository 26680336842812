// Colors

/**
gg: Generic Gradient
mg: Matching Gradient
*/
@use "sass:math";

$color-gg-1: #180d1e;
$color-gg-2: #522a42;
$color-gg-3: #964a56;
$color-gg-4: #d0775a;
$color-gg-5: #f4b35b;
$color-gg-6: #f9f871;
$color-mg-1: $color-gg-1;
$color-mg-2: #2b3046;
$color-mg-3: #38556f;
$color-mg-4: #457f94;
$color-mg-5: #5caab2;
$color-mg-6: #85d5c8;

$color-light: white;
$color-dark: black;
$color-bg-dark: $color-gg-1;

/// Number of columns bootstrap grid
/// @type Numbers
$grid-columns: 12;

/// Width of bootstrap gutter grid
/// @type Numbers
$grid-gutter-width: 30px;

/// Breakpoints screen size
/// @type Maps
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1590px,
);

/// Maximum container size according to screen size
/// @type Maps
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1530px,
);

/// Maximum container size according to screen size
/// @type Maps
$columns-widths: (
  sm: math.div(map-get($container-max-widths, sm), 12),
  md: math.div(map-get($container-max-widths, md), 12),
  lg: math.div(map-get($container-max-widths, lg), 12),
  xl: math.div(map-get($container-max-widths, xl), 12),
  xxl: math.div(map-get($container-max-widths, xxl), 12),
);

@use "sass:math";

@import "../global/variables.scss";
@import "../global/mixins.scss";

/* Helper Function */
$base-parallax-perspective: 16px;

@function parallax-scale-factor($z-distance) {
  @return math.div(
    $base-parallax-perspective - $z-distance,
    $base-parallax-perspective
  );
}

/* Parallax Styles */
.parallax-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
}

.layer {
  position: absolute;
  bottom: -1px;
  width: 100%;

  min-width: 1200px;

  @include media-breakpoint-down(sm) {
    // left: -75%;
  }
  @include media-breakpoint-up(xl) {
    bottom: -128px;
  }
  @include media-breakpoint-up(xxl) {
    bottom: -256px;
  }

  $layers: 7;

  @for $i from 1 through $layers {
    &--#{$i} {
      z-index: -$i;
    }
  }

  &--sun {
    z-index: -50;
    .sun-object {
      fill: hsl(60, 15%, 100%);
    }
  }
}
.background {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: #ffd5a3;
  z-index: -100;
}
.brightness-overlay {
  z-index: 500;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color-bg-dark;
  opacity: 0;
}

.background-static {
  transform: translateX(-32%);
  height: 100vh;
}

.header-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 2000;
  display: flex;
  justify-content: center;
  padding-bottom: 22vh;
  @include media-breakpoint-up(md) {
    padding-bottom: 20vh;
  }
}
.header {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  color: $color-light;
  @include set-container-layout();
  @include media-breakpoint-down(md) {
    flex-direction: column;
    justify-content: flex-end;
  }
  &__main {
    width: 280px;
    height: 280px;
    padding: 48px;
    object-fit: contain;
    object-position: center;
    border-radius: 100%;
    background-color: $color-gg-1;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(md) {
      align-self: flex-start;
      margin-bottom: 10vh;
    }
  }
  &__motto {
    font-size: 1.2rem;
  }
  &__words {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    line-height: 1.2;
    font-weight: bold;
    font-size: 3rem;
    @include media-breakpoint-down(lg) {
      font-size: 2rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 2rem;
      align-self: flex-start;
    }
  }
}

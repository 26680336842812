@import "../global/variables.scss";
@import "../global/mixins.scss";

.container {
  --chevron-size: 64px;
  --scroller-padding: 10%;
  @include media-breakpoint-down(lg) {
    --scroller-padding: 20%;
  }
  @include media-breakpoint-down(sm) {
    --chevron-size: 32px;
    --scroller-padding: 10%;
  }

  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .chevron {
    font-size: var(--chevron-size);
    position: absolute;
    &--left {
      left: calc(var(--scroller-padding) / 2 - var(--chevron-size) / 2);
    }
    &--right {
      right: calc(var(--scroller-padding) / 2 - var(--chevron-size) / 2);
    }
    &:hover {
      cursor: pointer;
    }
  }
  .scroller {
    // fix width overflow on small devices
    max-width: 100vw;

    overflow-x: scroll;
    display: flex;
    scroll-snap-type: x mandatory;
    transition: background-color 0.2s;
    padding: 24px 0;

    scrollbar-width: none;
    // scrollbar-width: thin;
    scrollbar-color: $color-light $color-dark;

    &:hover {
      scrollbar-color: rgba($color-light, 0.5) $color-dark;
    }

    &::-webkit-scrollbar {
      display: none;
      height: 6px; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: $color-dark; /* color of the tracking area */
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-light, 0.5);
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: $color-light;
    }
  }
  .item {
    min-width: 100%;
    display: flex;
    scroll-snap-align: center;
    padding: 0 var(--scroller-padding);

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }

    &__picture {
      width: 50%;
      padding-right: 24px;
      object-fit: contain;

      @include media-breakpoint-down(lg) {
        width: 100%;
        padding-right: 0;
      }
    }
    &__content {
      width: 50%;
      padding-left: 24px;
      @include media-breakpoint-down(lg) {
        width: 100%;
        padding-left: 0;
      }
    }
    &__link,
    &__description a {
      text-decoration: underline;
    }
  }
}

.stars,
.twinkling {
  position: fixed;
  top: 0;
  left: 0vw;
  width: 100vw;
  height: 100%;
  display: block;
}

.stars {
  background-position-x: center;
  background-position-y: top;
  background-repeat: repeat;
  background-color: transparent;
  background-image: url("/stars.webp");
  opacity: 0.7;
  z-index: -2000;
}

.twinkling {
  z-index: -1000;
  animation: move-twink-back-dark 400s linear infinite;
  @media (prefers-reduced-motion) {
    animation: none;
  }
}

@keyframes move-twink-back-dark {
  from {
    background: transparent url(/twinkling_dark.svg) repeat top center;
    background-position: 0 0;
    opacity: 0;
  }
  1% {
    opacity: 0.4;
  }
  2% {
    opacity: 1;
  }
  50% {
  }
  to {
    background-position: -10000px 5000px;
  }
}

@import "../global/variables.scss";
@import "../global/mixins.scss";

$conception-animation-duration: 1.5s;
$conception-animation-delay: 0.5s;

@keyframes conception-mask {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
    height: 100%;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}

.wrap-text {
  & > :first-child {
    position: relative;
    transition: color $conception-animation-duration*0.5;
    transition-delay: $conception-animation-duration*0.5 +
      $conception-animation-delay;
    &::after {
      color: $color-light;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      word-wrap: break-word;
      overflow-y: hidden;
      animation-duration: $conception-animation-duration*0.5;
      animation-name: conception-mask;
      animation-fill-mode: forwards;
      animation-delay: $conception-animation-delay;
      content: "————————————————————————————————————————————————————————————————————————————————————————————————————";
    }
  }
  &.masked {
    & > :first-child {
      color: $color-bg-dark;
      transition: none;
      &::after {
        opacity: 1;
        animation: none;
      }
    }
  }
}

.wrap-image {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  &.masked {
    .mask {
      opacity: 1;
      animation: none;
    }
    & > :first-child {
      opacity: 0;
      transition: none;
    }
  }

  & > :first-child {
    transition: opacity $conception-animation-duration*0.5;
    transition-delay: $conception-animation-duration*0.5 +
      $conception-animation-delay;
  }
  .mask {
    $line-width: 1px;

    position: absolute;
    height: 100%;
    width: 100%;
    transform: scale(1.5);

    border: $line-width solid $color-light;

    animation-duration: $conception-animation-duration*0.5;
    animation-name: conception-mask;
    animation-fill-mode: forwards;
    animation-delay: $conception-animation-delay;

    .mask__line {
      position: absolute;
      height: $line-width;
      background: $color-light;
      width: 140%;
      &:nth-of-type(1) {
        transform: rotate(-45deg);
        top: 50%;
        left: -20%;
      }
      &:nth-of-type(2) {
        transform: rotate(45deg);
        top: 50%;
        left: -20%;
      }
    }
  }
}

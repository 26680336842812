@import "../global/variables.scss";
@import "../global/mixins.scss";

.wrap {
  position: relative;
}
.button {
  position: fixed;
  top: 56px;
  right: 32px;
  z-index: 5000;
  @include media-breakpoint-down(sm) {
    top: 40px;
    right: 8px;
  }
  &__flashing .button__circle {
    animation: flashCircle 10s linear infinite;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }
  &__circle {
    margin-left: auto;
    color: $color-light;
    border: 2px solid transparent;
    border-radius: 50%;
    background: $color-gg-3;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__circle-closed {
    &:hover {
      animation: hoverCircle 0.2s linear forwards;
      @media (prefers-reduced-motion) {
        animation: none;
      }
      cursor: pointer;

      .button__icon {
        opacity: 1;
      }
      @include media-breakpoint-down(lg) {
        animation: none;
      }
    }
  }
  &__circle-opened {
    animation: none;
    border-color: white;
  }
  &__icon {
    position: absolute;
    opacity: 0.85;
    font-size: 32px;
    margin-right: 2px;
    @include media-breakpoint-down(lg) {
      opacity: 1;
    }
  }
  &__icon-close {
    position: absolute;
    // top: 8px;
    // right: 8px;
    color: $color-light;
    font-size: 1.5rem;
    cursor: pointer;
  }
}
.modal {
  z-index: 4000;
  position: fixed;
  top: 72px;
  right: -25vw;
  width: 25vw;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba($color-mg-3, 0.95);
  color: white;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  border: solid 1px white;
  border-radius: 16px;
  @include media-breakpoint-down(sm) {
    width: 80vw;
    min-width: 200px;
  }
  h3 {
    font-size: 1.2rem;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  h4 {
    font-size: 1.1rem;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  p {
    font-size: 1rem;
  }
  &__opened {
    right: 48px;
    transition: right 0.5s ease-in-out, opacity 0.5s ease-in-out;
    @media (prefers-reduced-motion) {
      transition: none;
    }
    @include media-breakpoint-down(sm) {
      right: 32px;
    }
  }
  &__closed {
    right: -500px;
    opacity: 0;
    pointer-events: none;
    transition: right 0.5s ease-in-out, opacity 0.5s ease-in-out;
    @media (prefers-reduced-motion) {
      transition: none;
    }
  }
}

@keyframes flashCircle {
  from {
    border: 2px solid transparent;
  }
  49% {
    border: 2px solid transparent;
  }
  50% {
    border: 2px solid white;
  }
  56% {
    border: 2px solid transparent;
  }
  to {
    border: 2px solid transparent;
  }
}

@keyframes hoverCircle {
  from {
    border: 2px solid transparent;
  }
  to {
    border: 2px solid white;
  }
}

@import "./variables.scss";

// Apply CSS to screens larger than the breakpoint size
@mixin media-breakpoint-up($breakpoint) {
  @media screen and (min-width: map-get($grid-breakpoints, $breakpoint)) {
    @content;
  }
}

// Apply CSS to screens below the breakpoint size
@mixin media-breakpoint-down($breakpoint) {
  @media screen and (max-width: map-get($grid-breakpoints, $breakpoint)) {
    @content;
  }
  @media screen and (max-device-width: map-get($grid-breakpoints, $breakpoint)) {
    @content;
  }
}

// Apply CSS to normal screen height media
@mixin normal-media-height {
  @media screen and (min-height: 665px) and (max-height: 1000px) {
    @content;
  }
}

// Apply CSS to small screen height media
@mixin small-media-height {
  @media screen and (max-height: 665px) {
    @content;
  }
}

// Apply CSS to large screen height media
@mixin large-media-height {
  @media screen and (min-height: 1000px) {
    @content;
  }
}

@mixin set-container-layout {
  @each $size, $value in $container-max-widths {
    @include media-breakpoint-up($size) {
      max-width: $value;
    }
  }
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

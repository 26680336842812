@import "../global/variables.scss";
@import "../global/mixins.scss";
@import "../animations/sky.scss";

$conception-animation-duration: 2.5s;

@keyframes conception-mask {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
    height: 100%;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 0.5s;
  background-color: $color-bg-dark;
  &--bg-0 {
    background-color: $color-bg-dark;
  }
  &--bg-1 {
    background-color: transparent;
  }
  &--bg-2 {
    background-color: transparent;
  }
  &--bg-3 {
    background-color: transparent;
  }
  &--bg-4 {
    transition: background-color 3s;
    background-color: rgba($color-mg-2, 0.8);
  }
  &--bg-5 {
    transition: background-color 1.5s;
    background-color: $color-mg-3;
  }
  &--bg-6 {
    background-color: $color-mg-5;
  }
  &--bg-7 {
    background-color: $color-mg-5;
  }
}
.introduction {
  position: relative;
  margin: 80px 0;
  @include media-breakpoint-up(md) {
    margin: 100px 0;
  }
  @include media-breakpoint-up(xl) {
    margin: 120px 0;
  }
  @include set-container-layout();
  text-align: center;
}
.tiles {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @include set-container-layout();
  @include media-breakpoint-down(xl) {
    flex-wrap: wrap-reverse;
    justify-content: center;
    justify-content: space-around;
  }
}

.workflow {
  position: relative;
  overflow-x: hidden;
}

.section {
  $section-width: 60%;
  @include set-container-layout();
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 80px;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 88px;
  }

  &__body {
    position: relative;
    width: $section-width;
    min-width: 512px;
    align-self: center;

    @include media-breakpoint-down(md) {
      width: 100%;
      min-width: auto;
    }
  }
  &__how {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
    }
    &-body {
      flex-shrink: 1;
    }
    &-picto-wrap {
      @include media-breakpoint-up(sm) {
        margin-left: 40px;
      }
    }
    &-picto {
      height: 96px;
      width: 96px;
    }
  }

  &--workflow {
    margin-top: 112px;
    .section__title {
      margin: 10vh 0;
      margin-bottom: 5vh;
      @include set-container-layout();
      text-align: center;
      z-index: 20;
    }

    .section__body {
      li {
        padding: 5px 0;
        line-height: 1.4;
      }
      strong {
        color: $color-gg-5;
      }
    }
  }

  &--design {
    .svg-color-palette {
      position: absolute;
      height: 100%;
      $el-width: 256px;
      width: $el-width;
      right: -$el-width;
      @include media-breakpoint-down(lg) {
        $el-width: 152px;
        width: $el-width;
        right: -$el-width;
      }
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    .section__title {
      @include media-breakpoint-down(sm) {
        position: relative;
        z-index: 1200; // be over the design decoration element
      }
    }
  }
  &--dev {
    .section__emphasis {
      color: $color-gg-5;
    }
    // .section__content {
    //   // color: $color-mg-6;
    // }
    .section__how-title {
      color: $color-gg-6;
    }
    .dev-how {
      &__title {
        color: $color-gg-5;
        margin-top: 0;
        margin-bottom: 0;
      }
      &__description {
        color: $color-mg-6;
        margin-top: 0;
        margin-bottom: 0;
        & p {
          margin-top: 0;
          margin-bottom: 0;
          margin-left: 16px;
        }
      }
    }
    .section__our-tech {
      font-size: 1.125rem;
      a {
        text-decoration: underline;
      }
    }
    .section__tech-img {
      margin-top: 48px;
      display: flex;
      justify-content: center;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
      }
    }
    .section__tech-url {
      margin: 0 16px;
    }
  }
  &--team {
    .section__body {
      width: 100%;
    }
    .section__title {
      margin-left: auto;
      margin-right: auto;
      width: $section-width;
      min-width: 512px;
      @include media-breakpoint-down(md) {
        width: 100%;
        min-width: auto;
      }
    }
    .team {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &__member {
        width: 25%;
        margin: 40px 4%;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;

        max-width: 300px;

        @include media-breakpoint-down(md) {
          width: 40%;
        }
        @include media-breakpoint-down(sm) {
          width: 80%;
          max-width: 240px;
        }

        .member {
          &__image {
            border-radius: 100%;
            max-width: 220px;
          }
          &__profession {
            font-weight: bold;
            margin-bottom: 16px;
          }
          &__passion {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  &--portfolio {
    max-width: none;
    padding: 0;
    align-items: center;
    .section__header {
      @include set-container-layout();
      width: 100%;
    }
    .section__title {
      margin-left: auto;
      margin-right: auto;
      width: $section-width;
      min-width: 512px;
      @include media-breakpoint-down(md) {
        width: 100%;
        min-width: auto;
      }
    }
    .section__body {
      width: 100%;
      position: relative;
      z-index: 150;
    }
  }
  &--contact {
    width: 100%;
    margin-bottom: 0;
    z-index: 100;
    text-align: center;
  }
}
.footer-pic {
  position: relative;
  height: 100vh;
  width: 100%;
  margin-top: -60vh;
  &__credits {
    position: absolute;
    bottom: 16px;
    right: 16px;
    opacity: 0.5;
  }
}
.footer {
  width: 100%;
  background-color: $color-mg-1;
  padding: 40px 128px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  @include media-breakpoint-down(lg) {
    column-gap: 16px;
  }
  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
    text-align: center;
  }
  @include media-breakpoint-down(sm) {
    padding-left: 80px;
    padding-right: 80px;
  }
  &__links {
    height: 100%;
    @include media-breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }
  &__link {
    padding-bottom: 8px;
    text-decoration: underline;
    a:hover {
      color: $color-gg-4;
      text-decoration: underline;
    }
  }
  &__socialNetworks {
    display: flex;
    justify-content: center;
  }
  &__socialIcon {
    padding-right: 16px;
  }
}
.goodIt {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-end;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
  &__link {
    text-decoration: underline;
    font-weight: 500;
    font-size: 1.25rem;
  }
  &__text {
    padding-right: 16px;

    @include media-breakpoint-down(sm) {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
  // &__logo {
  //   @include media-breakpoint-down(sm) {
  //     display: none;
  //   }
  // }
}

.glowy {
  text-shadow: 0 0 24px rgb(192 219 255 / 28%), 0 0 12px rgb(65 120 255 / 14%);
}

@import "../global/variables.scss";
@import "../global/mixins.scss";

.button {
  align-self: center;
  padding: 8px 16px;
  background-color: $color-mg-2;
  color: #fff;
  border-color: $color-mg-2;
  border: 2px solid;
  border-radius: 4px;
  font-size: 1.3rem;
  transition: 0.2s;
  @media (prefers-reduced-motion) {
    transition: none;
  }
  width: fit-content;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  &:hover {
    cursor: pointer;
    background-color: $color-mg-5;
    color: $color-light;
    border-color: $color-light;
  }
}

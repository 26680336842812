@import "../global/variables.scss";
@import "../global/mixins.scss";

.container {
  width: 30%;
  max-width: 400px;
  padding: 32px;
  border-radius: 8px;
  background-color: $color-light;
  color: $color-dark;
  transition: box-shadow 0.4s;

  backface-visibility: hidden;
  @include media-breakpoint-down(xl) {
    width: 45%;
    margin-bottom: 48px;
    &:nth-child(2) {
      order: 1;
    }
  }
  @include media-breakpoint-down(md) {
    width: 100%;
    &:nth-child(2) {
      order: 3;
    }
    &:nth-child(1) {
      order: 2;
    }
  }
  &:hover {
    box-shadow: 0 0 40px rgba(208, 228, 255, 0.363),
      0 0 12px rgba(94, 138, 250, 0.308);
  }
}
.pictos {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center; // Handle stretch bug on iOS
  margin-bottom: 32px;
}
.picto {
  width: 20%;
  word-break: break-all;
  word-wrap: break-word;
}
.title {
  text-align: center;
  color: $color-mg-2;
}
.description {
  text-align: center;
  color: $color-gg-1;
}
